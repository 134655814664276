export function castToInt(num: string | number): number {
  if (typeof num === 'string') {
    num = Number.parseInt(num as string)
    num = Number.isNaN(num) ? 0 : num
  }
  return num
}

export function floorToThousands(num: number | string): number {
  return Math.floor(castToInt(num) / 1000) * 1000
}

export function ceilToThousands(num: number | string): number {
  return Math.ceil(castToInt(num) / 1000) * 1000
}

export function forceToInt(input: unknown): number {
  input = Number.parseInt(String(input).replace(/\D+/g, ''))
  input = Number.isNaN(input) ? 0 : input
  return input as unknown as number
}

export function removeNonNumeric(input: unknown) {
  return String(input).replace(/\D+/g, '')
}
